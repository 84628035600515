import axios from 'axios'
import {
  Toast,
  Dialog
} from 'vant'
import store from "../store/"
import { baseURL } from '@/utils/config'



const service = axios.create({
  baseURL,
  timeout: 10000
})

// 添加请求拦截器--------------------------------------------
service.interceptors.request.use(
  config => {
    store.commit("updateShowLoading",true)
    // console.log(store.state.loadnum)
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    //   mask: false,
    //   duration: 10000
    // })
    // 判断是否有 token
    if (store.state.userStatus.token) {
      config.headers['access-token'] = store.state.userStatus.token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器-------------------------------------------
service.interceptors.response.use(
  response => {
    if(store.state.loadnum>1){
      store.commit("updateLoadnum",store.state.loadnum-1)
    }else{
      store.commit("updateShowLoading",false)
      // store.commit("updateLoadnum",1)
    }
    // Toast.clear()
    // store.commit("updateShowLoading",false)
    const res = response.data
    // console.log(res)
    if (res.code !== 0) {
      Toast.fail(`${res.msg}`)
      if (res.code === 1001) {
        let openid = store.state.openid
        Dialog.alert({
          title: '提示',
          message: `${res.msg}`,
        }).then(() => {
          // 重新获取token
          // location.href=location.protocol+'//'+location.host+'/clairvoyance/'+'?openid='+store.state.openid
          location.href = location.protocol + '//' + location.host + '/' + '?openid=' + openid
        })
      }
      switch (res.code) {
        case 8002:
          location.href = location.protocol + '//' + location.host + '/payIndex'
          break;
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    store.commit("updateShowLoading",false)
    return Promise.reject(error)
  }
)

export default service
