import request from '@/utils/request'

const table={
  // 获取可查看门店列表
  getShopList(data) {
    return request({
      url: '/clairvoyance/boss/default/shop-list',
      method: 'post',
      data
    })
  },
  // 切换门店
  changeNodeId(data) {
    return request({
      url: '/clairvoyance/boss/default/change-node-id',
      method: 'post',
      data
    })
  },
  // 获取营销七日走势图
  getRevenueChartData(data) {
    return request({
      url: '/clairvoyance/boss/revenue/get-revenue-chart-data',
      method: 'post',
      data
    })
  },
  // 获取最后同步时间
  getSynchronizeTime(data) {
    return request({
      url: '/clairvoyance/boss/revenue/get-synchronize-time',
      method: 'post',
      data
    })
  },
  // 获取营收汇总数据
  getRevenueSummary(data) {
    return request({
      url: '/clairvoyance/boss/revenue/get-revenue-summary',
      method: 'post',
      data
    })
  },
  // 获取会员类型数量
  getMemberTypeRate(data) {
    return request({
      url: '/clairvoyance/boss/revenue/get-member-type-rate',
      method: 'post',
      data
    })
  },
  // 获取营收数据
  getChargeRecord(data) {
    return request({
      url: '/clairvoyance/boss/revenue/get-charge-record',
      method: 'post',
      data
    })
  },
  // 项目分析
  getProjectsData(data) {
    return request({
      url: '/clairvoyance/boss/analysis/projects-analysis',
      method: 'post',
      data
    })
  },
  // 分析七日走势图
  getRevenueAnalysisChart(data) {
    return request({
      url: '/clairvoyance/boss/analysis/get-revenue-analysis-chart',
      method: 'post',
      data
    })
  },
  // 营业分析
  getRevenueByTime(data) {
    return request({
      url: '/clairvoyance/boss/analysis/get-revenue-by-time',
      method: 'post',
      data
    })
  },
  // 会员分析
  getMemberByTime(data) {
    return request({
      url: '/clairvoyance/boss/analysis/get-member-by-time',
      method: 'post',
      data
    })
  },
  // 获取房间类型列表
  getRoomTypeList(data) {
    return request({
      url: '/clairvoyance/boss/room/get-room-type-list',
      method: 'post',
      data
    })
  },
  // 获取房间列表
  getRoomList(data) {
    return request({
      url: '/clairvoyance/boss/room/get-room-list',
      method: 'post',
      data
    })
  },
  // 获取技师类型
  getTechnicianTypeList(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-type-list',
      method: 'post',
      data
    })
  },
  // 获取技师列表
  getTechnicianList(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-list',
      method: 'post',
      data
    })
  },
  // 获取技师详情
  getTechnicianDetail(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-detail',
      method: 'post',
      data
    })
  },
  // 获取技师人员流动（入职离职统计）
  getTechnicianTurnover(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-turnover',
      method: 'post',
      data
    })
  },
  // 获取技师统计(类型-状态)
  getTechnicianStatistics(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-statistics',
      method: 'post',
      data
    })
  },
  // 获取技师排行榜
  getTechnicianRanking(data) {
    return request({
      url: '/clairvoyance/boss/technician/get-technician-ranking',
      method: 'post',
      data
    })
  }
}
export default table
